import { Box, Container, Flex, Grid } from 'components/box';
import { useRouter } from 'next/router';
import { Text } from 'components/typography';
import { css } from '@emotion/react';
import { closeMenu } from '../../../../../redux/global/actions';
import { useDispatch } from 'react-redux';
import Clock from 'components/_global/header/top-bar/clock';
import Actions from 'components/_global/header/actions';
import TopTab from 'components/_global/header/top-bar/top-tab';
import { Link } from 'components/configurable-routing';
import Logo from '../../../../../shared/resources/images/logo.svg';
import { useState, useEffect, useRef, memo } from 'react';
import {
  RenderControl,
  ClientOnly,
} from 'components/_shared/widgets/render-control';
import { SearchBar } from 'components/_shared/widgets/search';
import { getPermanentShops } from '../../../../../util/get-permanent-shops';
import { HeaderDesignEnum } from 'types/interface';
import { TopBarProps } from 'components/_global/header/top-bar/types';
import { WhiteBar } from 'components/_global/header/top-bar/white-bar';
import { Category } from 'types/types';
import DismissableMessages from 'components/dismissable-messages';
import Toaster from 'components/_global/header/toaster';
import { zIndices } from 'constants/z-indices';
import useShowNewTab from 'hooks/use-show-new-tab';
import { asPermanentShopEnum } from '../../../../../util/permanent-shops';

const CATEGORY_BAR_SCROLL_THRESHOLD = 1250;
const HEADER_SHRINK_PX = 55;
const CATEGORY_BAR_HEIGHT_PX = 34;

const permanentShops = getPermanentShops();

const BackgroundGradient = () => (
  <Box
    css={theme => css`
      height: 100%;
      width: 105%;
      position: absolute;
      overflow: hidden;
      z-index: ${zIndices.TEN_BELOW};
      background-image: linear-gradient(
        to right,
        ${theme.gradientConfettiPalette.default.start},
        ${theme.gradientConfettiPalette.default.middle},
        ${theme.gradientConfettiPalette.default.end}
      );
    `}
  />
);

const MenuBar = ({
  selectedTopTab,
  isSearchActive,
  toggleSearch,
  canSearch,
  isMenuLoading,
  setIsMenuLoading,
  cartClassName,
}: {
  selectedTopTab: string | null;
  isSearchActive?: boolean;
  toggleSearch?: () => void;
  canSearch?: boolean;
  isMenuLoading: boolean;
  setIsMenuLoading: (set: boolean) => void;
  cartClassName?: string;
}) => {
  const showNewTabBadge = useShowNewTab();

  return (
    <Flex className="show-for-desktop">
      <Box flex="1" bg="blacker" pl={3} />

      {/* centered content */}
      <Flex
        width="100%"
        css={theme => css`
          max-width: ${theme.extendedMaxWidth}px;
        `}
      >
        <Box flex="1" bg="blacker" />

        <Flex alignItems="center">
          <TopTab
            href="/"
            isSelected={selectedTopTab === 'home'}
            variant={HeaderDesignEnum.Chunky}
          >
            Today&apos;s deals
          </TopTab>

          {permanentShops &&
            permanentShops.map(shop => (
              <TopTab
                key={shop.id}
                dynamicUrl="/shops/[id]"
                href={`/shops/${shop.id}`}
                isSelected={selectedTopTab === shop.id}
                variant={HeaderDesignEnum.Chunky}
                isNewTab={showNewTabBadge(asPermanentShopEnum(shop.id))}
              >
                {shop.name}
              </TopTab>
            ))}

          <TopTab
            href="/clearance-sale"
            isSelected={selectedTopTab === 'clearancesale'}
            variant={HeaderDesignEnum.Chunky}
          >
            Clearance
          </TopTab>

          <TopTab
            href="/gift-vouchers"
            isSelected={selectedTopTab === 'gift-vouchers'}
            variant={HeaderDesignEnum.Chunky}
          >
            Gift Vouchers
          </TopTab>
        </Flex>

        <Flex
          flex="1"
          bg="blacker"
          alignItems="center"
          justifyContent="flex-end"
          css={css`
            gap: 13px;
          `}
        >
          <RenderControl type="client" screen="desktopUp">
            <Actions
              isMenuLoading={isMenuLoading}
              setIsMenuLoading={() => setIsMenuLoading(true)}
              isSearchActive={isSearchActive}
              toggleSearch={toggleSearch}
              canSearch={canSearch}
              variant={HeaderDesignEnum.Chunky}
              cartClassName={cartClassName}
            />
          </RenderControl>
        </Flex>
      </Flex>

      <Box flex="1" bg="blacker" pr={3} />
    </Flex>
  );
};

const PrimaryBar = ({
  hideClock,
  showAdoHeader,
  isSearchActive,
  toggleSearch,
  canSearch,
  headerTaglineText,
  headerTaglineTitle,
  isMenuLoading,
  setIsMenuLoading,
  logoClassName,
  adoClassName,
  detailsClassName,
}: {
  hideClock?: boolean;
  showAdoHeader?: boolean;
  isSearchActive?: boolean;
  toggleSearch?: () => void;
  canSearch?: boolean;
  headerTaglineText?: string;
  headerTaglineTitle?: string;
  isMenuLoading: boolean;
  setIsMenuLoading: (set: boolean) => void;
  logoClassName?: string;
  adoClassName?: string;
  detailsClassName?: string;
}) => (
  <Flex
    py={0}
    px={3}
    mx={0}
    width="100%"
    justifyContent="center"
    css={theme => css`
      @media ${theme.mediaQueries.tinyScreenOnly} {
        padding: 0 ${theme.space[2]}px;
      }
    `}
  >
    <Container
      py={0}
      px={0}
      css={theme => css`
        max-width: ${theme.extendedMaxWidth}px;
        @media ${theme.mediaQueries.tinyScreenOnly} {
          padding: 0;
        }
      `}
    >
      <Grid
        gridTemplateColumns={[
          isSearchActive && canSearch ? '1fr auto 1fr' : '1fr auto 1fr',
          null,
          isSearchActive && canSearch
            ? '1fr auto 1fr'
            : showAdoHeader
            ? '1fr 1fr 1fr'
            : '1fr auto',
        ]}
        gridTemplateAreas={[
          isSearchActive && canSearch
            ? "'logo details actions' 'search search search'"
            : "'logo details actions'",
          null,
          isSearchActive && canSearch
            ? "'logo search details'"
            : showAdoHeader
            ? "'logo ado details'"
            : "'logo details'",
        ]}
        gridRowGap="6px"
        gridColumnGap={[2, 3]}
        alignItems="center"
      >
        <Flex justifyContent="flex-start" alignItems="center">
          <Link
            href="/"
            title="home"
            rel="nofollow"
            className={logoClassName}
            gridArea="logo"
            css={css`
              display: flex;
            `}
          >
            <Logo
              preserveAspectRatio="xMidYMid meet"
              css={theme => css`
                cursor: pointer;
                width: auto;

                padding: 6px;
                height: 40px;
                margin-left: -7px;
                margin-bottom: -2px;
                height: 44px;
                margin-bottom: -3px;

                @media ${theme.mediaQueries.tinyScreenOnly} {
                  padding: 6px 0 4px;
                  height: 34px;
                  margin-left: 0px;
                  margin-bottom: 0px;
                }

                @media ${theme.mediaQueries.desktopUp} {
                  padding: 8px 0 6px;
                  height: 73px;
                  margin-left: 0;
                  margin-bottom: 0;
                }
              `}
            />
          </Link>
        </Flex>

        {isSearchActive && canSearch && (
          <Flex
            alignItems="center"
            justifyContent="center"
            pb={isSearchActive && canSearch ? [0, 3, 0] : 0}
            pt={isSearchActive && canSearch ? [1, 2, 0] : 0}
            gridArea="search"
          >
            <SearchBar
              toggleSearch={toggleSearch}
              variant={HeaderDesignEnum.Chunky}
            />
          </Flex>
        )}

        {showAdoHeader && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className={[
              'show-for-desktop',
              ...(adoClassName ? [adoClassName] : []),
            ].join(' ')}
            gridArea="ado"
          >
            <Text
              fontSize="xl"
              fontWeight="bold"
              fontFamily="header"
              color="white"
            >
              AnotherDayOnly
            </Text>
          </Flex>
        )}

        <Flex
          alignItems="center"
          justifyContent="center"
          justifySelf="flex-end"
          className={detailsClassName}
          gridArea="details"
          css={
            !hideClock
              ? css`
                  --y-offset: 3px;
                `
              : undefined
          }
        >
          {!hideClock && (
            <ClientOnly>
              <Clock variant={HeaderDesignEnum.Chunky} />
            </ClientOnly>
          )}

          {headerTaglineText && headerTaglineTitle && (
            <>
              <Flex
                className="show-for-tablet-only"
                color="white"
                justifyContent="center"
              >
                <Text fontFamily="header" fontWeight="bold">
                  {headerTaglineTitle}
                  :&nbsp;
                </Text>
                <Text fontFamily="header">{headerTaglineText}</Text>
              </Flex>

              <Box
                className="show-for-desktop"
                color="white"
                pl={3}
                py="10px"
                fontWeight="bold"
                fontFamily="header"
                textAlign="right"
              >
                <Text as="h1" fontSize="1.4rem">
                  {headerTaglineTitle}:
                </Text>
                <Text
                  textAlign="right"
                  fontWeight="regular"
                  fontSize="1.25rem"
                  mt={1}
                >
                  {headerTaglineText}
                </Text>
              </Box>
            </>
          )}
        </Flex>

        <RenderControl type="client" screen="tabletDown">
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            gridArea="actions"
            css={theme => css`
              gap: 13px;

              @media ${theme.mediaQueries.tinyScreenOnly} {
                gap: 6px;
              }
            `}
          >
            <Actions
              isMenuLoading={isMenuLoading}
              setIsMenuLoading={() => setIsMenuLoading(true)}
              isSearchActive={isSearchActive}
              toggleSearch={toggleSearch}
              canSearch={canSearch}
              variant={HeaderDesignEnum.Chunky}
            />
          </Flex>
        </RenderControl>
      </Grid>
    </Container>
  </Flex>
);

const CategoryBar = memo(
  ({
    categories,
    date,
    pathname,
    className,
  }: {
    categories: Category[];
    date?: string | string[];
    pathname: string;
    className?: string;
  }) => (
    <Flex
      position="absolute"
      left={0}
      bottom={HEADER_SHRINK_PX - CATEGORY_BAR_HEIGHT_PX}
      // we don't need an actual zIndex in our scale, this is just to ensure it's above its peers (ie. the toaster)
      zIndex={1}
      width="100%"
      height="100%"
      alignItems="flex-end"
      className={className}
    >
      <WhiteBar>
        {categories.map(category => (
          <TopTab
            key={category.id}
            dynamicUrl="/category/[id]"
            href={`/category/${category.id}${date ? `/?when=${date}` : ''}`}
            isSelected={pathname === `/category/${category.id}`}
          >
            {category.name}
          </TopTab>
        ))}
      </WhiteBar>
    </Flex>
  )
);

const classNames = {
  header: 'header-primary-bar',
  logo: 'header-logo',
  details: 'header-clock',
  cart: 'header-cart',
  categories: 'header-categories',
  ado: 'header-ado',
};

interface ScrollRef {
  isChanged: boolean;
  scrollDir?: 'up' | 'down';
  scrollDirOrigin?: number;
}

const TopBar = ({
  selectedTopTab,
  hideClock,
  showAdoHeader,
  isSearchActive,
  toggleSearch,
  canSearch,
  headerTaglineText,
  headerTaglineTitle,
  categories,
  disableHeaderShrinking,
}: TopBarProps) => {
  const dispatch = useDispatch();

  const {
    events,
    asPath,
    query: { when: date },
  } = useRouter();

  const [isMenuLoading, setIsMenuLoading] = useState(false);

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      setIsMenuLoading(false);
      dispatch(closeMenu());
    });
  }, [events, dispatch]);

  const [isChanged, setIsChanged] = useState(false);
  const scrollRef = useRef<ScrollRef>({ isChanged: false });

  const hasCategories = !!categories && categories.length > 0;

  useEffect(() => {
    if (!disableHeaderShrinking) {
      const scroll = () => {
        const el = document.documentElement;
        const scrolling = scrollRef.current;

        if (typeof scrolling.scrollDirOrigin === 'undefined') {
          scrolling.scrollDirOrigin = el.scrollTop;
          return;
        }

        if (
          (el.scrollTop > CATEGORY_BAR_SCROLL_THRESHOLD ||
            el.scrollTop > ((el.scrollHeight - el.clientHeight) / 100) * 30) &&
          scrolling.scrollDirOrigin + 500 < el.scrollTop
        ) {
          scrolling.scrollDir = 'down';
          scrolling.scrollDirOrigin = el.scrollTop;
        } else if (scrolling.scrollDirOrigin - 25 > el.scrollTop) {
          scrolling.scrollDir = 'up';
          scrolling.scrollDirOrigin = el.scrollTop;
        }

        const shouldSet = scrolling.scrollDir === 'down';
        if (shouldSet !== scrolling.isChanged) {
          setIsChanged(shouldSet);
          scrolling.isChanged = shouldSet;
        }
      };

      window.addEventListener('scroll', scroll);
      return () => window.removeEventListener('scroll', scroll);
    }
  }, [disableHeaderShrinking]);

  return (
    <>
      <Box
        position="relative"
        className={isChanged ? 'hidden' : undefined}
        css={theme => css`
          @media ${theme.mediaQueries.mobileOnly} {
            .${classNames.categories} {
              transition: transform 0.3s ease-in-out;
              will-change: transform;
            }

            &.hidden {
              .${classNames.categories} {
                transform: translateY(${HEADER_SHRINK_PX}px);
              }
            }
          }

          @media ${theme.mediaQueries.tabletOnly} {
            .${classNames.categories} {
              transition: transform 0.3s ease-in-out;
              will-change: transform;
            }

            &.hidden {
              .${classNames.categories} {
                transform: translateY(${HEADER_SHRINK_PX}px);
              }
            }
          }

          @media ${theme.mediaQueries.desktopUp} {
            .${classNames.header} {
              transition: transform 0.3s ease-in-out;
              will-change: transform;
            }
            .${classNames.logo} {
              transition: transform 0.3s ease-in-out;
              transform-origin: bottom left;
              will-change: transform;
            }
            .${classNames.details} {
              transition: transform 0.3s ease-in-out;
              transform-origin: bottom right;
              will-change: transform;
            }
            .${classNames.cart} {
              transition: transform 0.3s ease-in-out;
              will-change: transform;
            }
            .${classNames.ado} {
              transition: transform 0.3s ease-in-out;
              transform-origin: bottom;
              will-change: transform;
            }

            &.hidden {
              .${classNames.header} {
                transform: translateY(${HEADER_SHRINK_PX * -1}px);
              }
              .${classNames.logo} {
                transform: scale(0.7);
              }
              .${classNames.details} {
                transform: scale(0.7) translate(-90px, var(--y-offset, 0px));
              }
              .${classNames.cart} {
                transform: translateY(63px);
              }
              .${classNames.ado} {
                transform: scale(0.7) translateY(8px);
              }
            }
          }
        `}
      >
        <Box
          as="header"
          className={classNames.header}
          zIndex={zIndices.HEADER_BAR}
          position="relative"
        >
          <BackgroundGradient />

          <MenuBar
            selectedTopTab={selectedTopTab}
            isSearchActive={isSearchActive}
            toggleSearch={toggleSearch}
            canSearch={canSearch}
            isMenuLoading={isMenuLoading}
            setIsMenuLoading={setIsMenuLoading}
            cartClassName={classNames.cart}
          />

          <PrimaryBar
            hideClock={hideClock}
            showAdoHeader={showAdoHeader}
            isSearchActive={isSearchActive}
            toggleSearch={toggleSearch}
            canSearch={canSearch}
            headerTaglineText={headerTaglineText}
            headerTaglineTitle={headerTaglineTitle}
            isMenuLoading={isMenuLoading}
            setIsMenuLoading={setIsMenuLoading}
            logoClassName={classNames.logo}
            adoClassName={classNames.ado}
            detailsClassName={classNames.details}
          />
        </Box>

        {hasCategories && (
          <CategoryBar
            categories={categories}
            date={date}
            pathname={asPath}
            className={classNames.categories}
          />
        )}
      </Box>

      <ClientOnly>
        <Box
          className={isChanged ? 'hidden' : undefined}
          css={theme => css`
            transition: transform 400ms ease-in-out;
            will-change: transform;

            ${hasCategories &&
            css`
              &.hidden {
                transform: translateY(${CATEGORY_BAR_HEIGHT_PX}px);
              }
            `}

            @media ${theme.mediaQueries.desktopUp} {
              transition: transform ${hasCategories ? 150 : 300}ms ease-in-out;
              &.hidden {
                transform: translateY(
                  ${hasCategories
                    ? (HEADER_SHRINK_PX - CATEGORY_BAR_HEIGHT_PX) * -1
                    : HEADER_SHRINK_PX * -1}px
                );
              }
            }
          `}
        >
          <DismissableMessages />
          <Toaster />
        </Box>
      </ClientOnly>
    </>
  );
};

export default TopBar;

import { useCallback, useEffect } from 'react';
import { css, Theme } from '@emotion/react';
import { Box } from '../box';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { X as XIcon } from 'react-feather';
import { RootState } from 'typesafe-actions';
import { DismissableNotification } from '../../types/types';
import { removeDismissableNotification } from '../../redux/global/actions';
import { zIndices } from 'constants/z-indices';

const styles = {
  error: (theme: Theme) => css`
    width: 100%;
    margin: 0;
    z-index: ${zIndices.HEADER_BAR};
    background: ${theme.colors.lightPink};
    text-align: center;
    border-bottom-color: ${theme.colors.danger};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: ${theme.colors.danger};
    a {
      color: ${theme.colors.danger};
      text-decoration: underline;
      font-weight: 600;
    }
  `,
};

const Message = ({
  notification,
}: {
  notification: DismissableNotification;
}) => {
  const dispatch: Dispatch = useDispatch();

  const dismiss = useCallback(() => {
    dispatch(removeDismissableNotification(notification.id));
  }, [dispatch, notification.id]);

  useEffect(() => {
    if (notification.timeout && notification.timeout > 0) {
      const timer = setInterval(
        () => dispatch(removeDismissableNotification(notification.id)),
        notification.timeout
      );
      return () => clearInterval(timer);
    }
  }, [dispatch, notification.id, notification.timeout]);

  if (!notification.message) return null;

  return (
    <Box fontSize="r" py="2" css={styles[notification.variant]}>
      <span
        css={theme => css`
          display: grid;
          grid-template-columns: 1fr auto;
          max-width: ${theme.maxHeaderWidth}px;
          margin: auto;
          word-break: break-word;
          max-height: 100px;
          overflow: hidden;
        `}
      >
        {notification.message}
        {notification.dismissable && (
          <span
            css={theme => css`
              cursor: pointer;
              padding: 0 ${theme.space[2]}px;
            `}
            role="link"
            tabIndex={-3}
            onKeyDown={dismiss}
            onClick={dismiss}
          >
            <XIcon
              css={theme => css`
                color: ${theme.colors.danger};
              `}
              size={17}
            />
          </span>
        )}
      </span>
    </Box>
  );
};

const Wrapper = () => {
  const dismissables = useSelector(
    (state: RootState) => state.global.notifications
  );

  if (dismissables.length < 1) return null;

  return (
    <>
      {dismissables.map(message => (
        <Message key={message.id} notification={message} />
      ))}
    </>
  );
};

export default Wrapper;
